import config from '@/api-services/_config';

export default {
  fetch() {
    return fetch(`${config.url}/subaccount:index/get-all`, { method: 'GET'}).then(res => res.json());
  },

  create(data) {
    return fetch(`${config.url}/subaccount:index/create`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
  },

  delete(data) {
    return fetch(`${config.url}/subaccount:index/delete`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
  }
  
};
