<template>
    <div class="modal" ref="modal" id="addSubAccountModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <div class="d-flex align-items-center">
              <h5 class="mb-0 fw-sbold">{{title}}</h5>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeModelModal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt-2">
            <form @submit.prevent="addSubAccount">
              <div class="form-group mb-3">
                <label class="mb-2">Name</label>
                <div class="small text-muted">
                  Enter the name
                </div>
                <input x-webkit-speech ref="input" v-model="name" type="text" class="form-control mb-1" />
              </div>
              <div class="form-group mb-3">
                <label class="mb-2">Email Address</label>
                <div class="small text-muted">
                  Enter their Email Address
                </div>
                <input x-webkit-speech ref="input" v-model="email" :readonly="action === 'update'" type="email" class="form-control mb-1" />
              </div>
              <div class="form-group mb-3" v-if="action === 'create'">
                <label class="mb-2">Password</label>
                <div class="small text-muted">
                  Enter the password for the account
                </div>
                <input x-webkit-speech ref="input" v-model="password" type="password" class="form-control mb-1" />
              </div>
              <div class="form-group mb-3">
                <label class="mb-2">Phone Number <small>(Optional)</small></label>
                <div class="small text-muted">
                  Enter the phone number of the person using the account
                </div>
                <input x-webkit-speech  ref="input" v-model="phone_number" type="text" class="form-control mb-1" />
              </div>
              <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-secondary fs-6 rounded-3 px-4">
                  <div v-if="false" class="spinner-border spinner-border-sm me-2"></div>
                  {{ btnText }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  
  </template>
  
  <script>
    import { Modal } from 'bootstrap';
  
    export default {
      name: 'AddSubAccountModal',
      data() {
        return {
          title: 'Create Sub Account',
          action: 'create',
          id: null,
          phone_number: null,
          email: null,
          password: null,
          name: null,
          btnText: 'Add Account',

        }
      },
      computed: {
        modal() {
          return new Modal(this.$refs.modal);
        }
      },
      methods: {
        addSubAccount() {
          const [isValidate, message] = this.validateForm();
          if(!isValidate) {
            this.$toast(message, 'error');
            return;
          }
          
          this.$emit('onAdd', this.getSubAccountData());
          this.resetForm();
          this.modal.hide();
        },
        getSubAccountData() {
          return {
            action: this.action,
            id: this.id,
            phone_number: this.phone_number,
            email: this.email,
            password: this.password,
            name: this.name
          }
        },
        validateForm() {
          if((!this.email || this.email.trim() == '') && this.action === 'create') {
            return [false, 'Please enter email', 'error'];
          }

          if((!this.password || this.password.trim() == '') && this.action === 'create') {
            return [false, 'Please enter password', 'error'];
          }

          const re = /^(?=.*[a-z])(?=.*\d).{8,}$/i;
          if (!re.test(this.password) && this.action === 'create') {
            return [false, 'Password does not contain at least 1 number/letter, 8 character minimum requirement.', 'error'];
          }


          if(!this.name || this.name.trim() == '') {
            return [false, 'Please enter name', 'error'];
          }

          return [true, '']
        },
        resetForm() {
          this.action = 'create';
          this.id = null;
          this.phone_number = null;
          this.email = null;
          this.password = null;
          this.name = null;
          this.btnText = 'Add Account';
          this.title = 'Create Sub Account'
        },
        show(action, subAccount) {
          if(action === 'update') {
            this.action = action;
            this.id = subAccount.id;
            this.phone_number = (!subAccount.number || subAccount.number == 'null') ? '' : subAccount.number;
            this.email = subAccount.email;
            this.name = subAccount.name;
            this.btnText = 'Update Account';
            this.title = 'Update Sub Account'
          }
          this.modal.show();
          if(action === 'create') {
            this.resetForm();
          }
          
        },
      }
    }
  </script>
  

  <style lang="scss" scoped>
    .radio-button-label{
      margin-left: 5px;
    }
  </style>
